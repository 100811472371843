import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../UserContext'; // Importa il contesto
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';

function NavBar() {    
    const { userData } = useContext(UserContext); // Ottieni userData dal contesto
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);    
    const [displayedUserData, setDisplayedUserData] = useState(userData);
    const navigate = useNavigate();
    
    useEffect(() => {    
      
    }, [userData]);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);

        // Utilizza jQuery per aggiungere/rimuovere la classe
        $('.sidebar').toggleClass('active_sidebar', isMobileMenuOpen); 
        };

    useEffect(() => {
        // Simula il comportamento di jQuery per l'apertura/chiusura del menu
        $('.sidebar_icon').on('click', toggleMobileMenu);

        // Pulisci l'event listener quando il componente viene smontato
        return () => {
            $('.sidebar_icon').off('click', toggleMobileMenu);
        };
    });

    const toggleSubMenu = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };
        
    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token'); 
        setDisplayedUserData(userData);
        navigate('/');
    };

    return (        
       <nav className={`sidebar ${isMobileMenuOpen ? "active_sidebar" : ""}`}>
            <div className="sidebar_icon d-lg-none" onClick={() => {toggleMobileMenu();}}>
                <i className="ti-menu"></i>
            </div>

            <div className="logo d-flex justify-content-between">
                <a href="/amministratore/dashboard"><img src="/img/logo-animus-operandi.png" alt="Animus Operandi" /></a>
                <div className="sidebar_close_icon d-lg-none" onClick={() => {toggleMobileMenu();}}>
                    <i className="ti-close"></i>
                </div>
            </div>
            <ul id="sidebar_menu">
                <li className="mm-active">
                    <a href="/amministratore/dashboard">
                        <i className="ti-home"></i>
                        <span>Dashboard</span>                        
                    </a>
                </li>
                
                <li>
                    <a href="/amministratore/richieste-ricevute">
                        <i className="ti-import"></i>
                        <span>Richieste ricevute</span>
                    </a>
                </li> 
                
                <li className={activeMenu === 'anagrafiche' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('anagrafiche')} aria-expanded={activeMenu === 'anagrafiche'}>
                        <i className="ti-user"></i>
                        <span>Utenti
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'anagrafiche' ? 'rotate-180' : ''} style={{float: 'right'}} /> {/* Aggiungi l'icona */}
                        </span>                        
                    </a>
                    <ul style={{ display: activeMenu === 'anagrafiche' ? 'block' : 'none' }}>
                        <li><a href="/amministratore/utenti">Utenti</a></li>
                        <li><a href="/amministratore/nuovo-utente">Nuovo Utente</a></li>
                    </ul>
                </li>
                

                <li className={activeMenu === 'operatori' ? 'mm-active' : ''}>
                    <a className="has-arrow" href="#!"  onClick={() => toggleSubMenu('operatori')} aria-expanded={activeMenu === 'operatori'}>
                        <i className="ti-headphone-alt"></i>
                        <span>Operatori
                            <FontAwesomeIcon icon={faChevronDown} className={activeMenu === 'operatori' ? 'rotate-180' : ''} style={{float: 'right'}} /> {/* Aggiungi l'icona */}
                        </span>                        
                    </a>
                    <ul style={{ display: activeMenu === 'operatori' ? 'block' : 'none' }}>
                        <li><a href="/amministratore/operatori">Operatori</a></li>
                        <li><a href="/amministratore/nuovo-operatore">Nuovo Operatore</a></li>
                    </ul>
                </li>
                
                <li>
                    <a href="/amministratore/supporto">
                        <i className="ti-help-alt"></i>
                        <span>Supporto tecnico</span>
                    </a>
                </li>
                
                <li className="mt-3 pt-3" style={{borderTop: "1px solid #CCC"}}>
                    <a href="#!" onClick={handleLogout}>
                        <i className="ti-power-off text text-danger"></i>
                        <span className="text-danger">Uscita</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
};

export default NavBar;